import Swiper, { Autoplay, Navigation, Thumbs } from 'swiper';

$ = jQuery();

Swiper.use([Thumbs, Navigation, Autoplay]);

const banerThunmbsSlider = new Swiper('.baner__thumbs-slider', {
    slidesPerView: document.querySelector('.baner__thumbs-slider').dataset
        .slides,
    freeMode: true,
    watchSlidesProgress: true,
});

const banerSlider = new Swiper('.baner__slider', {
    slidesPerView: 1,
    loop: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    },

    breakpoints: {
        992: {
            autoplay: {
                delay: 5000,
            },
        },
    },

    navigation: {
        nextEl: '.baner__slide-arrow--next',
        prevEl: '.baner__slide-arrow--prev',
    },
    thumbs: {
        swiper: banerThunmbsSlider,
        slideThumbActiveClass: 'baner__thumbs-slide--active',
    },
});

banerSlider.on('slideChange', function () {
    const activeIndex = banerSlider.activeIndex;
    if($('.baner__slide video').length)  {
        $('.baner__slide').find('video')[0].pause();
        $('.baner__slide').find('video')[0].currentTime = 0;
        if ($('.baner__slide').eq(activeIndex).hasClass('baner__slide--video')) {
            $('.baner__slide').eq(activeIndex).find('video')[0].play();
        }
    }
});
